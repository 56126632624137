import { Component } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.less'],
})
export class SignInModalComponent {
  protected readonly environment = environment;

  platformList = [
    {
      imgPath: environment.imagePath + '/header/client.svg',
      alt: 'Client',
      url: environment.hkB2bUrl + '/b2b/login;platform=igp',
    },
    {
      imgPath: environment.imagePath + '/header/adviser.svg',
      alt: 'Adviser',
      url: environment.hkB2bUrl + '/b2b-adviser/login;platform=igp',
    },
  ];

  openWindow(url: string): void {
    window.open(url);
  }
}
