<div class="text-center py-16">
  <p class="text-md text-black-7">
    {{ "menu.sign.in" | translate }}
  </p>

  <div class="grid md:grid-cols-2 gap-4 mt-8">
    <div class="box-wrapper" *ngFor="let item of platformList">
      <div class="flex flex-col items-center" (click)="openWindow(item.url)">
        <img [src]="item.imgPath" [alt]="item.alt" />
        <p class="mt-2 text-md text-black-7">{{ item.alt }}</p>
      </div>
    </div>
  </div>
</div>
